
import { Button } from '@/ui';
import { defineComponent } from 'vue';
import { PageHeader } from '@/components';
import { ResizeService } from '@/utils'
import { useEventBus } from '@vueuse/core'
import { alprelaxEventKey } from '@/bus/eventKey'

export default defineComponent({
    components: { Button, PageHeader },
    data() {
        return {
            icons: {
                logo: '<?xml version="1.0" encoding="utf-8"?><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 997.7 939.3" enable-background="new 0 0 997.7 939.3" xml:space="preserve"><path fill="none" stroke="#163D64" stroke-width="50" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M572.9,902.7L283.5,194.4C201.6,361.1,119.7,527.8,37.7,694.5"/><path fill="none" stroke="#163D64" stroke-width="50" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M960,744.9L670.6,36.6c-82,166.7-163.9,333.4-245.9,500.1"/></svg>',
            },
            eventBus: useEventBus(alprelaxEventKey),
            windowHeight: 0
        };
    },
    created(){
        (document.getElementById('app') as HTMLElement).scrollIntoView({ behavior: 'smooth' });
        this.windowHeight = window.innerHeight
        this.eventBus.on((e) => {
            if (e === 'onResize') this.doResize()
        });     

    },
    methods: {
        handleShowRegisterForm() {
            this.$router.push('/registerform')
        },
        handleShowLoginForm() {
            this.$router.push('/loginform')
        },  
        doResize() {
            this.windowHeight = ResizeService.height
        },        
    },
})
